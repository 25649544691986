<template>
    
    <tabla-datos 
        stripedRows
        :value="expedientesFiltrados" 
        :loading="loading"
        :total="total"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 25, 50]"
        tableStyle="min-width: 50rem">
        <template #empty> No hay datos. </template>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   <input class="form-control" type="checkbox" v-model="checkGlobal">
                </div>
            </template>
            <template #body="{ data }">
                <input type="checkbox" v-model="data.seleccionado">
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Nº Expediente
                </div>
            </template>
            <template #body="{ data }">
                {{ data.codigo }}
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Fecha Cita y hora
                </div>
            </template>
            <template #body="{ data }">
                {{ data.fecha_inicio }}
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Datos del asegurado
                </div>
            </template>
            <template #body="{ data }">
                <p v-html="data.cliente"></p>
            </template>
        </Column>
        <Column>
            <template #header>
                <select class="form-control" v-model="OperarioSeleccionado">
                    <option value="">Operario</option>
                    <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
                </select>
            </template>
            <template #body="{ data }">
                <p v-html="data.operario"></p>                
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Resumen de parte de trabajo
                </div>
            </template>
            <template #body="{ data }">
                {{ data.descripcion_visitadores }}
            </template>
        </Column>
    </tabla-datos>
    <div class="row" style="margin: 10px 0px; gap: 10px; align-items: center;">
        <p style="margin: 0;">Desactivar seleccionadas</p>
        <div>
            <button @click="desactivar" class="btn btn-sm btn-light" style="border: 1px solid grey;">Desactivar</button>
        </div>
    </div>
   
</template>
   
   <script>
   import { PwgsApi } from '../../../services/PwgsApi';
   import DataTable from 'primevue/datatable';
   
   export default {
       components: {         
           "tabla-datos": DataTable,
       },
       props:{
         tipo_servicio: String
       }
       ,
       computed:{
        expedientesFiltrados(){
            let filtrados = this.expedientes;
            if(this.OperarioSeleccionado){
                filtrados = filtrados.filter(exp => exp.id_operario == this.OperarioSeleccionado);
            }
            return filtrados;
        }
       },
       data() {
           return {
            operarios:[],
            OperarioSeleccionado:'',
            checkGlobal: false,
            total: '',
            expedientes: [], 
            loading:false,                            
           }
       },
       methods: {
            async desactivar(){
                var exps = this.expedientes.filter(expediente => expediente.seleccionado == true);
                var idexps = [];
                try{
                    if(exps.length > 0){
                        const api = new PwgsApi();
                        for(let exp of exps){
                            idexps.push(exp.id_aviso);
                        }
                        await api.put('avisos/revisar-avisos', {tipo:'visitas', modo: this.tipo_servicio, ids_avisos:idexps});
                        this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Alertas desactivadas correctamente', life: 2000 });
                        this.OperarioSeleccionado=='';
                        this.obtenerExpedientes();
                        this.checkGlobal = false;        
                    }
                }catch(e){
                    console.log(e);
                }
            }, 
           async obtenerExpedientes() {
                this.loading = true;
               const api = new PwgsApi;       
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);
               this.expedientes = aux.datos;
               for(let expediente of this.expedientes){
                expediente.seleccionado = false;
               }
               this.total = aux.n_total_registros;
               this.loading = false;
           },
           async obtenerOperarios() {
            const api = new PwgsApi();
            var aux = await api.get('operarios/simple');
            this.operarios = aux.datos;
           },
           seleccionartodo(){
            for(let expediente of this.expedientes){
                expediente.seleccionado = this.checkGlobal;
            }
           }
       },
       created() {
           this.obtenerExpedientes();
           this.obtenerOperarios();
       },        
       watch:{
        checkGlobal(){            
            this.seleccionartodo();            
        }
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>
<template>
   <!----> <div class="card bg-gradient-primary">
        <div class="ribbon-wrapper ribbon-lg">
            <div class="ribbon bg-warning text-lg">
            TOP
            </div>
        </div>
        <div class="card-body box-profile">
            <h2 class=" text-center">94</h2>
            <p class="text-muted text-center text-white">{{ $t('general.puntuacion') }}</p>
        </div>
    </div>
</template>